enum ROUTES {
  NO_MATCH = '404',
  PERSON_CHS = 'persona',
  ADDRESS_CHS = 'domicilio',
  NIP_CHS = 'confirmacion-nip',
  FINAL_STEP_CHS = 'solicitud-enviada',
}

enum GO_TO_ROUTES {
  PERSON_CHS = 'domicilio',
  ADDRESS_CHS = 'confirmacion-nip',
  NIP_CHS = 'solicitud-enviada',
  FAQ = 'https://creditasmx.zendesk.com/hc/es-419/sections/360010167511-Pr%C3%A9stamos-con-garant%C3%ADa-de-inmueble',
}

export { GO_TO_ROUTES, ROUTES };
