import { navigateToUrl } from 'single-spa';
import { BaseRoutes } from './baseRoutes';
import { ROUTES } from './routes';

const prefix = (location: Location, ...prefixes: string[]): boolean =>
  prefixes.some(
    prefix => location.href.indexOf(`${location.origin}${prefix}`) !== -1,
  );

// Main roots
const mf404Page = (location: Location): boolean =>
  prefix(location, BaseRoutes.CHS_API + ROUTES.NO_MATCH);
const mfNavbar = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.CHS_API + ROUTES.PERSON_CHS,
    BaseRoutes.CHS_API + ROUTES.ADDRESS_CHS,
    BaseRoutes.CHS_API + ROUTES.NIP_CHS,
    BaseRoutes.CHS_API + ROUTES.FINAL_STEP_CHS,
    BaseRoutes.CHS_API + ROUTES.NO_MATCH,
  );

// Credit History Service
const mfPersonCHS = (location: Location): boolean =>
  prefix(location, BaseRoutes.CHS_API + ROUTES.PERSON_CHS);

const mfAddressCHS = (location: Location): boolean =>
  prefix(location, BaseRoutes.CHS_API + ROUTES.ADDRESS_CHS);

const mfNipCHS = (location: Location): boolean =>
  prefix(location, BaseRoutes.CHS_API + ROUTES.NIP_CHS);

const mfFinalStep = (location: Location): boolean =>
  prefix(location, BaseRoutes.CHS_API + ROUTES.FINAL_STEP_CHS);

// We can add an auto redirect using SingleSpa.navigateToUrl
const main = (location: Location): boolean => {
  if (
    location.pathname === BaseRoutes.CHS_API ||
    location.pathname ===
      BaseRoutes.CHS_API.substr(0, BaseRoutes.CHS_API.length - 1) ||
    location.pathname === '/'
  ) {
    navigateToUrl(BaseRoutes.CHS_API + ROUTES.PERSON_CHS);
  }

  const pathExists = Object.values(ROUTES).find((route: string) => {
    const existsInCreditHistoryService =
      location.pathname === `${BaseRoutes.CHS_API}${route}`;
    return existsInCreditHistoryService;
  });

  if (!pathExists) {
    navigateToUrl(BaseRoutes.CHS_API + ROUTES.NO_MATCH);
  }

  return true;
};

export default {
  main,
  mfNavbar,
  mfFinalStep,
  mf404Page,
  mfPersonCHS,
  mfAddressCHS,
  mfNipCHS,
};
