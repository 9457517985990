import { start, registerApplication } from 'single-spa';
import Activity from './activityFns';
import * as serviceWorker from './serviceWorker';
import { GO_TO_ROUTES } from './routes';
import { BaseRoutes } from './baseRoutes';

const registerApps = (): void => {
  registerApplication(
    'main',
    () => import('./microfrontends/app.lifecycles'),
    Activity.main,
  );
  registerApplication(
    'mfNavbar',
    () => import('./microfrontends/mfNavbar/mfNavbar.lifecycle'),
    Activity.mfNavbar,
    {
      goTo: BaseRoutes.CHS_API,
    },
  );
  registerApplication(
    'mf404Page',
    () => import('./microfrontends/mf404Page/mf404Page.lifecycle'),
    Activity.mf404Page,
    {
      goTo: GO_TO_ROUTES.FAQ,
    },
  );

  registerApplication(
    'mfPersonCHS',
    () => import('./microfrontends/mfPersonCHS/mfPersonCHS.lifecycle'),
    Activity.mfPersonCHS,
    {
      goTo: BaseRoutes.CHS_API + GO_TO_ROUTES.PERSON_CHS,
    },
  );
  registerApplication(
    'mfAddressCHS',
    () => import('./microfrontends/mfAddressCHS/mfAddressCHS.lifecycle'),
    Activity.mfAddressCHS,
    {
      goTo: BaseRoutes.CHS_API + GO_TO_ROUTES.ADDRESS_CHS,
    },
  );
  registerApplication(
    'mfNipCHS',
    () => import('./microfrontends/mfNipCHS/mfNipCHS.lifecycle'),
    Activity.mfNipCHS,
    {
      goTo: BaseRoutes.CHS_API + GO_TO_ROUTES.NIP_CHS,
    },
  );
  registerApplication(
    'mfFinalStep',
    () => import('./microfrontends/mfFinalStep/mfFinalStep.lifecycle'),
    Activity.mfFinalStep,
    {
      goTo: GO_TO_ROUTES.FAQ,
    },
  );
};

registerApps();
start();

serviceWorker.unregister();
